<template>
  <v-dialog v-model="stateModal" persistent max-width="400">
    <v-card class="py-12 px-10">
      <h3 class="mb-12 text--center">
        {{ title }}
      </h3>
      <v-col>
        <v-row justify="space-between">
          <v-btn outlined @click="$emit('hideModal')"> キャンセル </v-btn>
          <v-btn dark :color="color" @click="$emit('apply')">
            {{ label }}
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalDelete",
  props: {
    stateModal: {
      type: Boolean,
      default: false,
    },
    hideModal: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    apply: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
