<template>
  <section class="col-7">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <modal-confirmation
      :state-modal="isModal"
      title="削除してもよろしいですか？"
      color="red"
      label="削除"
      @hideModal="toggleModal"
      @apply="deleteItem"
    />
    <h2 v-if="$route.meta === 'new'" class="mb-10">ページの登録</h2>
    <h2 v-if="$route.meta === 'edit'" class="mb-10">ページの編集</h2>

    <section>
      <div>
        <div>
          <v-text-field
            v-model="page.title"
            label="タイトル"
            type="text"
            :error-messages="errorMessages.title"
            required
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="page.slug"
            label="URL用文字列"
            type="text"
            :error-messages="errorMessages.slug"
            required
            outlined
          />
        </div>

        <div>
          <v-textarea
            v-model="page.body"
            label="本文"
            :error-messages="errorMessages.body"
            required
            outlined
          />
        </div>

        <div>
          <v-select
            v-model="page.status"
            label="ステータス"
            :items="status"
            item-text="label"
            item-value="value"
            :class="errorMessages.status ? 'mb-4' : ''"
            :error-messages="errorMessages.status"
            outlined
          />
        </div>
      </div>

      <hr class="mt-8 mb-4" />

      <v-col>
        <v-row v-if="$route.meta === 'new'" justify="end">
          <v-btn color="primary" large @click="save"> 保存 </v-btn>
        </v-row>
        <v-row v-if="$route.meta === 'edit'" justify="space-between">
          <v-btn color="red" large outlined @click="toggleModal">
            削除する
          </v-btn>
          <v-btn color="primary" large @click="edit"> 上書き保存 </v-btn>
        </v-row>
      </v-col>
    </section>
  </section>
</template>

<script>
import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";
import { getData, createData, updateData, deleteData } from "../../axios";

export default {
  name: "PagesEdit",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      id: "",
      isModal: false,
      loading: false,
      loadingMessage: "",
      pages: [],
      page: {
        id: "",
        title: "",
        body: "",
        slug: "",
        order: "",
        status: "draft",
      },
      errorMessages: {
        title: "",
        body: "",
        slug: "",
        order: "",
        status: "",
      },
      japaneseItems: {
        title: "タイトル",
        body: "本文",
        slug: "URL用文字列",
        order: "メニュー表示の順",
        status: "ステータス",
      },
      status: [
        {
          id: "status01",
          value: "draft",
          label: "下書き",
        },
        {
          id: "status02",
          value: "published",
          label: "公開",
        },
      ],
    };
  },

  computed: {
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0;
    },
  },

  mounted() {
    getData("pages").then((res) => {
      this.pages = res.data;
      if (this.$route.meta === "new") {
        this.page.order = res.data.length + 1;
      }
    });
    if (this.$route.meta === "edit") {
      getData(`pages/${this.$route.params.id}`).then((res) => {
        this.page = res;
      });
    }
  },

  methods: {
    toggleModal() {
      this.isModal = !this.isModal;
    },
    deleteItem() {
      this.loading = true;
      this.loadingMessage = "削除中...";
      deleteData(`pages/${this.$route.params.id}`)
        .then(() => {
          this.loading = false;
          this.loadingMessage = "";
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `「${this.page.title}」のデータを削除しました。`,
            color: "success",
            timeout: 2000,
          });
          if (this.$route.params.from) {
            const from = Object.assign({}, this.$route.params.from);
            const params = Object.assign({}, from);
            // 空文字のクエリーを無くす：例）words=''
            Object.keys(params).forEach((key) => {
              if (params[key] === "") delete params[key];
            });
            this.$router.push({ name: "pages", query: params });
          } else {
            this.$router.push({ name: "pages" });
          }
        })
        .catch(() => {
          this.loading = false;
          this.loadingMessage = "";
        });
    },
    save() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "保存中...";
        createData("pages", this.page)
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("snackbar/setSnackbar", {
              message: `「${this.page.title}」を追加しました。`,
              color: "success",
              timeout: 2000,
            });
            this.$router.push({ name: "pages" });
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      }
    },
    edit() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "更新中...";
        updateData(`pages/${this.$route.params.id}`, this.page)
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("snackbar/setSnackbar", {
              message: `「${this.page.title}」を更新しました。`,
              color: "success",
              timeout: 2000,
            });
            if (this.$route.params.from) {
              const from = Object.assign({}, this.$route.params.from);
              const params = Object.assign({}, from);
              // 空文字のクエリーを無くす：例）words=''
              Object.keys(params).forEach((key) => {
                if (params[key] === "") delete params[key];
              });
              this.$router.push({ name: "pages", query: params });
            } else {
              this.$router.push({ name: "pages" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      }
    },
    validate() {
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
      Object.keys(this.page).forEach((key) => {
        if (key === "id") return;
        if (key === "slug") this.checkUnique(key);
        this.checkRequired(key, ["title", "slug"]);
        this.checkMaxLength(key, 50, ["title", "slug"]);
        this.checkMaxLength(key, 2000, ["body"]);
      });
    },
    checkRequired(key, requiredList) {
      if (requiredList.indexOf(key) !== -1 && this.page[key].length === 0) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (key === item && this.page[key] && max < this.page[key].length) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkUnique(key) {
      Object.keys(this.pages).forEach((index) => {
        if (
          this.$route.meta === "edit" &&
          this.pages[index].id === Number(this.$route.params.id)
        )
          return;
        if (this.page[key] === this.pages[index][key]) {
          this.errorMessages[
            key
          ] = `「${this.page[key]}」はすでに存在する${this.japaneseItems[key]}です。`;
        }
      });
    },
  },
};
</script>
